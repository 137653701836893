const BASEURL ='https://backend.searchgurbani.com/api/'

export const API = {
    //common
    baseUrl: BASEURL,
    login: BASEURL + 'user/login',
    getHomeData: BASEURL + 'gethomecontent',
    updateHomeData: BASEURL + 'update_homecontent',
    getBanner: BASEURL + 'getbanners',
    updateBanner: BASEURL + 'updatebanners',
    bannerPath: 'https://backend.searchgurbani.com/public/banners/',

}
