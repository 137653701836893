import React from "react";
import "../assets/css/sidebar.css";
import { Link } from "react-router-dom";
// import testLogo from '../assets/images/logo_white.png';
import testLogo from "../assets/img/logo192.png";
import bannerImg from "../assets/img/UserManagement.png";
import DashBoard from "../assets/img/Dashboard.png";

const Sidebar = () => {
  return (
    <nav className="sidebar sidebar-offcanvas customClassB" id="sidebar">
      <div className="text-center sidebar-brand-wrapper">
        <img
          alt="logo"
          className="sidebar-brand brand-logo lg-Logo"
          src={testLogo}
        />
        <h4 style={{ color: "white" }}>Search Gurbani Admin</h4>
      </div>

      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <img src={DashBoard} alt="test" className="menu_icon" />
            <span className="menu-title">Home Content</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard/banner">
            <img src={bannerImg} alt="test" className="menu_icon" />
            <span className="menu-title">Banner</span>
          </Link>
        </li>
        
      </ul>
    </nav>
  );
};

export default Sidebar;
