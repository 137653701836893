import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from "react-router";
import "../../index.css";
import { Card, Dropdown } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Footer from '../../components/Footer'
import testLogo from "../../assets/img/logo.png";
import { API } from "../../config/api";
import { ApiHelper } from '../../helpers/ApiHelper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Axios from 'axios';
import Spinner from '../../components/Spinner';
import PopUp from '../../components/PopUp';
/* import { Card, CardBody, CardTitle, CardText } from "reactstrap"; */
import '../../assets/css/dashboard.css'
const Homepage = () => {
  const { state } = useOutletContext();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [datas, setDatas] = useState([]);
  const [banners, setBanners] = useState([]);
  const [tag, setTag] = useState('');
  const [head1, setHead1] = useState('');
  const [content1, setContent1] = useState('');
  const [head2, setHead2] = useState('');
  const [content2, setContent2] = useState('');
  const [head3, setHead3] = useState('');
  const [content3, setContent3] = useState('');
  const [head4, setHead4] = useState('');
  const [content4, setContent4] = useState('');
  const [content5, setContent5] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [id, setId] = useState('');
  const [priority, setPrioriry] = useState('');
  useEffect(() => {
    getData();
  }, [])
  const getData = async () => {
    setLoader(true);
    await ApiHelper.post(API.getHomeData)
      .then((resData) => {
        setLoader(false)
        console.log('INTRO', resData.data[0]);
        setDatas(resData.data)
        setHead1(resData.data[0].HEADING1);
        setContent1(resData.data[0].CONTENT1);
        setHead2(resData.data[0].HEADING2);
        setContent2(resData.data[0].CONTENT2);
        setHead3(resData.data[0].HEADING3);
        setContent3(resData.data[0].CONTENT3);
        setHead4(resData.data[0].HEADING4);
        setContent4(resData.data[0].CONTENT4);
        setContent5(resData.data[0].CONTENT5);
        setTag(resData.data[0].TAG)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const updateData = async () => {
    setLoader(true);
    const formData = {
      heading1: head1,
      content1: content1,
      content2: content2,
      heading2: head2,
      content3: content3,
      heading3: head3,
      content4: content4,
      tag: tag,
    };
    console.log("Submitted:", formData);
    await ApiHelper.post(API.updateHomeData, formData)
      .then((resData) => {
        setLoader(false)
        console.log('response', resData);
        if (resData.data.status === true) {
          setMessage(resData.data.message)
          setOpenPopUp(true);
          setTimeout(function () {
            setOpenPopUp(false);
          }, 2000);
          getData();
        }

      })
      .catch((err) => {
        setLoader(false);
        setMessage("Error !!! Please try again");
        console.log(err, 'err');
      });


  };
  return (
    <div className={state ? "sidebar_open" : "sidebar_close"}>
      <section className='content-sctn'>
        <h3 className='mb-3 mt-3'>Tagline</h3>
        <input onChange={(e) => setTag(e.target.value)}
          type="email"
          className="form-control" value={tag} />
        
      </section>
      <section >
        <h3 className='mb-3 mt-3'>Content 1</h3>
        <input onChange={(e) => setHead1(e.target.value)}
          type="email"
          className="form-control" value={head1} />
        <div className="mt-4 mb-3">
          <ReactQuill value={content1} onChange={setContent1} />
        </div>
      </section>
      {/*  <section>
        <div className="mt-4">
          <ReactQuill value={content2} onChange={setContent2} />
        </div>
      </section> */}
      <section>
        <h3>Content 2</h3>

        <div className="mt-4 mb-3">
          <ReactQuill value={content2} onChange={setContent2} />
        </div>
      </section>
      <section>
        <h3>Content 3</h3>
        <input onChange={(e) => setHead2(e.target.value)}
          type="email"
          className="form-control" value={head2} />
        <div className="mt-4 mb-3">
          <ReactQuill value={content3} onChange={setContent3} />
        </div>
      </section>
      <section className='mb-5'>
        <h3>Content 4</h3>
        <div className="mt-4 mb-5">
          <ReactQuill value={content5} onChange={setContent5} />
        </div>
      </section>
      <section className='mb-5'>
        <h3>Content 5</h3>
        <input onChange={(e) => setHead3(e.target.value)}
          type="email"
          className="form-control" value={head3} />
        <div className="mt-4 mb-5">
          <ReactQuill value={content4} onChange={setContent4} />
        </div>
      </section>
      <section className='update-section'>
        <button className='btn-actn' onClick={() => { updateData(); }}>Update</button>
      </section>
      <Footer sidebarEnabler={state} />
      {loader && <Spinner />}
      {openPopUp && <PopUp message={message} />}
    </div>
  );
};

export default Homepage;


