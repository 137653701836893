/// header file
import React, { useState, useEffect } from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/header.css";
import moment from "moment";
import testLogo from "../assets/img/logo192.png";
import profile from "../assets/img/profile.jpg";
import bellIC from "../assets/img/bellNew.svg";
import MenuOpen from "../assets/img/OpenMenu.svg";
import MenuClose from "../assets/img/Collapse.svg";

const Header = (props) => {
  const navigate = useNavigate();
  const pname = localStorage.getItem("playbook_name");

  const [playbookName, setPlaybookName] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [userData, setUserData] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [toggle, setToggle] = useState(false);

  const toggleLogic = () => {
    //toggle button for sidebar functionality
    setToggle(!toggle);
    props.getSidebarToggleState(toggle);
  };

  return (
    <header className={toggle ? "header_collapse" : "headWid"}>
      <Navbar bg="light" expand="lg">
        <div className="contain">
          <img
            className="toggleButton mt-1"
            style={{ height: "24px", width: "24px" }}
            src={!toggle ? MenuClose : MenuOpen}
            onClick={toggleLogic}
          />
          {toggle &&
            <div className="headerLogo">
              <Navbar.Brand className='logoWraper'>
                <img title='MergeFlo'
                  className="mainLogo"
                  src={testLogo} alt="MERGEFLO" />
              </Navbar.Brand>
            <h6 className="mt-2 mx-3">Search Gurbani Admin</h6>
            </div>
          }
          <div className="topRights">
            <button className="btn-actn"  onClick={() => {navigate("/login");}}>Logout</button>
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
