import React, { useState } from "react";
import Sidebar from "../../../components/SideBar";
import Header from "../../../components/Header";
import "../../../assets/css/layout.css";
import "../../../assets/css/sidebar.css";
import { Outlet } from "react-router";
import Footer from "../../../components/Footer";

function DashboardLayout() {
  const [sidebarEnabler, setSidebarEnabler] = useState(true);
  const getSidebarToggleState = (toggleState) => {
    setSidebarEnabler(toggleState);
  };
  return (
    <div className="flex-enabler">
      <div className="sidebarWraper">{sidebarEnabler && <Sidebar />}</div>
      <div
        className={
          sidebarEnabler ? "container_main" : "container_main containerSpace"
        }
      >
        <div>
          <Header
            getSidebarToggleState={(toggleState) => {
              getSidebarToggleState(toggleState);
            }}
          />
          <Outlet context={{ state: sidebarEnabler }} />
        </div>
        {/*<Footer sidebarEnabler={sidebarEnabler}/>*/}
      </div>
    </div>
  );
}
export default DashboardLayout;
