import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from "react-router";
import "../../index.css";
import { Card, Dropdown } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Footer from '../../components/Footer'
import testLogo from "../../assets/img/logo.png";
import { API } from "../../config/api";
import { ApiHelper } from '../../helpers/ApiHelper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Axios from 'axios';
import Spinner from '../../components/Spinner';
import PopUp from '../../components/PopUp';
/* import { Card, CardBody, CardTitle, CardText } from "reactstrap"; */
import '../../assets/css/dashboard.css'
const BannerPage = () => {
  const { state } = useOutletContext();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [datas, setDatas] = useState([]);
  const [banners, setBanners] = useState([]);  
  const [bannerImage, setBannerImage] = useState('');
  const [id, setId] = useState('');
  const [priority, setPrioriry] = useState('');
  useEffect(() => {
    getBanner();
  }, [])
  const getBanner = async () => {
    setLoader(true)
    await ApiHelper.post(API.getBanner)
      .then((resData) => {
        setLoader(false)
        console.log('BANNER', resData.data.data);
        setBanners(resData.data.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const onImageChange = (e, id, priority) => {
    // console.log('FILEBEFORE', id)
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      console.log('FILE', img)
      setBannerImage(img);
      setId(id);
      setPrioriry(priority);
    }
  };
  useEffect(() => {
    if (bannerImage !== '') {
      uploadImage();
    }
  }, [bannerImage, id, priority])

  const uploadImage = async () => {
    setLoader(true);
    const params = new FormData();
    console.log('####', bannerImage)
    console.log(bannerImage instanceof File);
    params.append('photo', bannerImage);
    params.append('priority', priority);
    params.append('id', id);
    for (let [key, value] of params.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const resData = await Axios.post(API.updateBanner, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoader(false)
      console.log('responseImage', resData);
      if (resData.data.status === true) {
        setMessage(resData.data.message)
        setOpenPopUp(true);
        setTimeout(function () {
          setOpenPopUp(false);
        }, 2000);
        getBanner();
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className={state ? "sidebar_open" : "sidebar_close"}>
      <section className='content-sctn'>
        <h3>Banner Edit</h3>
        <div className="table-responsive  p-3">
          <table className="table ">
            <thead>
              <tr>
                <th>Priority</th>
                <th>Banner</th>
                <th>Action</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="mt-5">
              {banners.map((item, index) => (
                <tr key={index}>
                  <td>{item.priority}</td>
                  <td className="py-1"><img
                    src={API.bannerPath + item.photo_name}
                    alt="banner"
                    style={{
                      height: "100px",
                      width: "150px",
                      //borderRadius: 100 / 2,
                      border: '1px solid lightgrey'
                    }}
                  /></td>
                  <td><input className="form-control" placeholder='Upload File' type='file'
                    onChange={(e) => onImageChange(e, item.ID, item.priority)} /></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <Footer sidebarEnabler={state} />
      {loader && <Spinner />}
      {openPopUp && <PopUp message={message} />}
    </div>
  );
};

export default BannerPage;


