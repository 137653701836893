import React from 'react';
import '../assets/css/PopUp.css';

 

const PopUp = (props) => {

     
        return (
            <div className="popupbackground">
                <div className="popupcontainer">
                    <div className="message">
                        <p>{props.message}</p>
                    </div>
                </div>
            </div>
        );
    
}
 
export default PopUp ;