import React, { useState, useEffect, } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "../views/authentication/Login";
import DashboardLayout from "../views/dashboard/layout/DashboardLayout";
import Homepage from "../views/dashboard/Home";
import BannerPage from "../views/dashboard/Banner";

const Routing = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
      <Routes>
        <Route  path="/" element={<Login />} />
        <Route  path="login" element={<Login />} />
        <Route  path="dashboard" element={<DashboardLayout />}>
        <Route index element={<Homepage />} /> 
        <Route path="banner" element={<BannerPage />} />    
        </Route>
       
      </Routes>
  )
}
export default Routing