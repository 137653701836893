import React, { Component } from 'react';
import { Rings } from "react-loader-spinner";
import '../assets/css/Spinner.css';

export class Spinner extends Component {
  render() {
    return (
      <div className='spinnerbackground'>
        <Rings
          height="80"
          width="80"
          radius="9"
          color='#dd761c'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}} 
          wrapperClass=""
          backdrop-filter= "blur(8px)" 
        />
      </div>
    )
  }
}

export default Spinner
