import React, {useState} from 'react';
import '../assets/css/footer.css';
const Footer = (props) => {
  return(
      <div className={props.sidebarEnabler?"footer":"footer_collapse"}>
        <p className='mt-1'>&copy; . All rights reserved </p>
      </div>
  )
};

export default Footer;
